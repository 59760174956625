var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.agreed
        ? _c("applicant-type-complete", {
            attrs: { applicantType: _vm.applicantType },
            on: {
              change: function($event) {
                return _vm.updateInvalidate()
              }
            }
          })
        : _vm._e(),
      !_vm.agreed
        ? _c("applicant-type-select", {
            attrs: {
              "hide-actions": _vm.applicantType === "Non Applicant",
              applicantType: _vm.applicantType
            },
            on: {
              "update:applicantType": function($event) {
                _vm.applicantType = $event
              },
              "update:applicant-type": function($event) {
                _vm.applicantType = $event
              },
              continue: _vm.updateContinue
            }
          })
        : _vm._e(),
      _c(
        "v-slide-y-transition",
        [
          !_vm.agreed &&
          _vm.applicantType === "Non Applicant" &&
          !_vm.search &&
          !_vm.showStatus
            ? _c("applicant-type-wizard", {
                attrs: {
                  property: _vm.application.property,
                  applicantType: _vm.applicantType
                },
                on: {
                  "update:applicantType": function($event) {
                    _vm.applicantType = $event
                  },
                  "update:applicant-type": function($event) {
                    _vm.applicantType = $event
                  },
                  search: function($event) {
                    _vm.search = true
                  },
                  change: _vm.handleWizardChange
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-fade-transition",
        [
          _vm.search
            ? _c("applicant-type-search", {
                attrs: { applicants: _vm.application.applicants },
                on: { complete: _vm.searchComplete }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-fade-transition",
        [
          _vm.showStatus
            ? _c("applicant-type-status", {
                attrs: { status: _vm.status },
                on: { update: _vm.statusUpdate }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }