<template>
    <div>
        <!-- Show Application Complete if we have authorized the PCA Non Applicant Flow -->
        <applicant-type-complete
            v-if="agreed"
            :applicantType="applicantType"
            @change="updateInvalidate()"
        >
        </applicant-type-complete>

        <applicant-type-select
            v-if="!agreed"
            :hide-actions="applicantType === 'Non Applicant'"
            :applicantType.sync="applicantType"
            @continue="updateContinue"
        >
        </applicant-type-select>

        <v-slide-y-transition>
            <applicant-type-wizard
                v-if="
                    !agreed &&
                    applicantType === 'Non Applicant' &&
                    !search &&
                    !showStatus
                "
                :property="application.property"
                :applicantType.sync="applicantType"
                @search="search = true"
                @change="handleWizardChange"
            ></applicant-type-wizard>
        </v-slide-y-transition>
        <v-fade-transition>
            <applicant-type-search
                v-if="search"
                :applicants="application.applicants"
                @complete="searchComplete"
            >
            </applicant-type-search>
        </v-fade-transition>
        <v-fade-transition>
            <applicant-type-status
                :status="status"
                v-if="showStatus"
                @update="statusUpdate"
            ></applicant-type-status>
        </v-fade-transition>
    </div>
</template>
<script>
export default {
    name: "applicant-type",
    components: {
        ApplicantTypeSelect: () => import("./ApplicantTypeSelect"),
        ApplicantTypeComplete: () => import("./ApplicantTypeComplete"),
        ApplicantTypeWizard: () => import("./ApplicantTypeWizard"),
        ApplicantTypeSearch: () => import("./ApplicantTypeSearch"),
        ApplicantTypeStatus: () => import("./ApplicantTypeStatus"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            applicantType: this.applicant.type, // interim applicant type we will use throughout
            search: false,
            showStatus: false,
            status: false,
        };
    },
    watch: {
        applicant: {
            immediate: true,
            handler(applicant) {
                if (applicant.authorizations.pca_non_applicant_agree) {
                    this.$emit("update", {
                        complete: true,
                    });
                }
            },
        },
    },
    computed: {
        agreed: {
            immediate: true,
            deep: true,
            get() {
                return this.applicant.authorizations.pca_non_applicant_agree;
            },
        },
    },
    methods: {
        updateContinue() {
            if (this.applicantType !== "Non Applicant") {
                // We are continuing as a Financially Responsible Applicant
                this.updateComplete();
            }
        },
        handleWizardChange(applicantType) {
            this.applicantType = applicantType;
            console.log("handleWizardChange %s", applicantType);
            this.updateComplete();
        },
        statusUpdate(applicantType) {
            this.applicantType = applicantType;
            this.updateComplete();
        },
        searchComplete(status) {
            // No longer searching
            this.search = false;
            // Enable status display
            this.showStatus = true;
            // We'll pass the result of the check for paid applicants
            this.status = status;
        },
        updateInvalidate() {
            this.$emit("update", {
                applicant: {
                    authorizations: Object.assign(
                        this.applicant.authorizations,
                        {
                            pca_non_applicant_agree: false,
                        }
                    ),
                },
            });
        },
        updateComplete() {
            console.log("Update complete with type = %s", this.applicantType);
            // Complete with the current status of the applicant type
            this.$emit("update", {
                complete: true,
                applicant: {
                    authorizations: Object.assign(
                        this.applicant.authorizations,
                        {
                            pca_non_applicant_agree: true,
                        }
                    ),
                    type: this.applicantType,
                },
            });
        },
    },
};
</script>

<style scoped>
</style>